<template>
  <div class="container--boxed">
    <router-link :to="{ name: 'login' }" class="cursor: pointer;">
      <i class="arrow left icon" />
      <strong>Anmeldung</strong>
    </router-link>
    <h1 class="headline">Passwort zurücksetzen</h1>
    <p>
      Bitte trage deine zuletzt genutzte E-Mail-Adresse ein, um weitere
      Anweisungen zum Zurücksetzen deines Passworts per E-Mail zu erhalten.
    </p>
    <form class="ui form" v-on:submit.prevent="sendReset">
      <div class="field required">
        <label>Deine E-Mail-Adresse</label>
        <input
          v-model="credentials.email"
          placeholder="max.muster@email.de"
          type="email"
          name="email"
          autocomplete="email"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="none"
          minlength="5"
          :disabled="loading"
          required
          @input="error = null"
        />
      </div>
      <div v-if="error" class="ui red message">
        <div class="header">Zurücksetzen fehlgeschlagen</div>
        <p>{{ error }}</p>
      </div>
      <button
        :class="{ loading }"
        :disabled="loading"
        class="ui fluid right labeled icon primary button large btn-submit"
        style="width: 100%; margin-top: 10px; font-weight: 600; height: 50px"
      >
        Passwort zurücksetzen
      </button>
    </form>
    <div v-if="sent" class="ui green message">
      <div class="header">Du hast Post!</div>
      <p>
        Du hast soeben eine E-Mail mit weiteren Anweisungen erhalten. Prüfe
        daher deinen Posteingang (und ggf. Spam-Ordner), ob eine neue E-Mail
        angekommen ist.
      </p>
      <router-link
        :to="{ name: 'login' }"
        class="ui button green"
        @click="back"
      >
        Zurück zur Anmeldung
      </router-link>
    </div>
  </div>
</template>

<script>
import firebase from "@firebase/app";
import "@firebase/auth";

export default {
  name: "RecoverUserPassword",
  metaInfo: {
    title: "Passwort zurücksetzen",
  },
  data() {
    return {
      loading: false,
      error: null,
      sent: false,
      credentials: {
        email: null,
      },
    };
  },
  computed: {
    user() {
      return firebase.auth().currentUser;
    },
  },
  mounted() {
    if (this.user) {
      this.$router.replace({ name: "posts" });
    }
  },
  methods: {
    sendReset() {
      this.loading = true;

      this.$store
        .dispatch("user/sendAdminRecoverPasswordEmail", this.credentials)
        .then(() => {
          this.sent = true;
        })
        .catch((err) => {
          if (err.code === "auth/user-not-found") {
            this.error =
              "Für diese E-Mail-Adresse ist kein Zugang registriert oder es wurde zwischenzeitlich gelöscht.";
          } else {
            this.error = err.message;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
